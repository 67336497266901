import React, { useRef, useState } from 'react'

import emailjs from '@emailjs/browser';

import MyImage from '../resources/misc/koala.png'


export default function ContactUsPage() {
  return (
    <div>
      <div className='franchise-form-bg-1 py-12 mx-auto max-w-[1200px]'>
        <ContactForm/>
      </div>
    </div>
  )
}


const ContactForm = () => {

  const formRef = useRef();
  const [sendMessage,setSendMessage] = useState('')
  const [disableButton,setDisableButton] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    
    setDisableButton(true)
    
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID, formRef.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                setSendMessage("Votre email a bien été envoyé !")
                setDisableButton(false)
                // Add any success message or logic here
            }, (error) => {
                console.error('Email sending failed:', error.text);
                 setSendMessage("Votre email n'a pas été envoyé !")
                // Add any error handling logic here
            });
  }

  return(
    <form ref={formRef} className='grid justify-center gap-3 old-paper-bg-1 py-5 px-3 shadow-lg mx-auto rounded-xl max-w-[600px] ' onSubmit={handleSubmit}>

      <h2 className='text-center text-2xl md:text-3xl uppercase font-paragraph tracking-wider'>Contactez-nous dès maitenant !</h2>
      <p className='text-center text-[var(--color-orange-3)] text-lg md:text-xl font-title tracking-wider' >Partagez avec nous vos opinions, demandes d'information, recommandations et plaintes en utilisant ce formulaire, afin que nous puissions vous répondre rapidement.</p>
      <img src={MyImage} alt='' className='w-[150px] mx-auto lg:w-[250px]'></img>

      <div className='grid'>
        
        <div className='grid md:grid-cols-2 gap-3'>

          <div className='grid'>
            <label className='franchise-label'>Votre Nom <span className='text-red-500 font-bold'>*</span></label>
            <input required name='user_last_name' type='text' placeholder='Nom' className='input-franchise'></input>
          </div>

          <div className='grid'>
            <label className='franchise-label'>Votre Prénom <span className='text-red-500 font-bold'>*</span></label>
            <input required name='user_first_name' type='text' placeholder='Prénom' className='input-franchise'></input>
          </div>
          
        </div>
      </div>
      
      <div className='grid'>
        <label className='franchise-label'>Pays <span className='text-red-500 font-bold'>*</span></label>
        <select name='user_pays' className='input-franchise'>
          <option value={'Belgique'}>Belgique</option>
          <option value={'France'}>France</option>
          <option value={'Luxembourg'}>Luxembourg</option>
          <option value={'Autre'}>Autre</option>
        </select>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Ville <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_city' type='text' placeholder='Ville' className='input-franchise'></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Téléphone <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_phone' type="tel" pattern="[0-9]*" placeholder='Téléphone'className='input-franchise' ></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Email <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_email' type='email' placeholder='Email' className='input-franchise'></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Message</label>
        <textarea name='user_message' placeholder='Votre message' className='input-franchise min-h-[150px]'></textarea>
      </div>
      
      
      <div className='pt-3 grid justify-center'>
        <p className='text-center font-bold min-h-10'>{sendMessage}</p>
        <button type='submit' className='button-3 disabled:bg-gray-400' disabled={disableButton}>Envoyer</button>
      </div>

    </form>
  )
}
