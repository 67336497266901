import React from 'react'

import CafeThe from '../resources/menu/cafe-the-2.jpg'
import Cookies from '../resources/menu/cookies-2.jpg'
import DonutGlace from '../resources/menu/donut-glace.jpg'
import Milkshakes from '../resources/menu/milkshakes.jpg'
import PersonaliserDonut from '../resources/menu/personaliser-donut.jpg'
import PrixDonut from '../resources/menu/prix-donut.png'
import Bagels from '../resources/menu/bagels.jpg'

export default function MenuPage() {
  return (
    <div className='text-white'>

      <div className='flex justify-center pb-2'>
        <img src={PrixDonut} alt='' className='rounded-xl'></img>
      </div>

      <div className='grid px-3 py-3 gap-5'>

        <img src={PersonaliserDonut} alt='' className='mx-auto rounded-xl'></img>
        <img src={DonutGlace} alt='' className='mx-auto rounded-xl'></img>
        <img src={Milkshakes} alt='' className='mx-auto rounded-xl'></img>
        <img src={Cookies} alt='' className='mx-auto rounded-xl'></img>
        <img src={CafeThe} alt='' className='mx-auto rounded-xl'></img> 
        <img src={Bagels} alt='' className='mx-auto rounded-xl'></img>        

      </div>
    

    </div>
  )
}
