import React from 'react'

import LOGO from '../resources/logo_Artboard_1.png'

import { FaFacebook,FaInstagram,FaLinkedin,FaTiktok } from "react-icons/fa";

import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <header className=''>

        <div className='md:hidden'>
            <div className='grid grid-flow-col items-center'>
                <Link className='' to={'/'}>
                    <img alt='logo' src={LOGO} className='w-[125px]' />
                </Link>
                <div className='flex flex-row gap-5 ml-auto px-5'>
                    <Link to={'https://m.facebook.com/people/JungleDonuts/61554717687868/'} target='_blank'>
                        <FaFacebook className='social-media-button'/>
                    </Link>
                    <Link to={'https://www.instagram.com/jungledonuts_be'} target='_blank'>
                        <FaInstagram className='social-media-button'/>
                    </Link>
                    {/* <Link>
                        <FaLinkedin className='social-media-button'/>
                    </Link> */}
                    <Link to={'https://www.tiktok.com/@jungledonuts'} target='_blank'>
                        <FaTiktok className='social-media-button'/>
                    </Link>
                </div>
            </div>
            <div className='grid grid-cols-4 justify-center items-center gap-5'>
                    <Link to={'/'} className='header-button'>
                        <span className='header-button-text'>Accueil</span>
                    </Link>
                    <Link to={"/menu"} className='header-button'>
                        <span className='header-button-text'>Menu</span>
                    </Link>
                    <Link to={'/devenir-franchise'} className='header-button'>
                        <span className='header-button-text'>Franchise</span>
                    </Link>
                    <Link to={'/nos-boutiques'} className='header-button'>
                        <span className='header-button-text'>Boutiques</span>
                    </Link>
                    <Link to={'/contactez-nous'} className='header-button'>
                        <span className='header-button-text'>Contact</span>
                    </Link>
            </div>
        </div>

        <div className='hidden md:flex items-center justify-between'>
            <div className='flex'>
                <Link className='' to={'/'}>
                    <img alt='logo' src={LOGO} className='w-[150px]' />
                </Link>
                <div className='lg:ml-[5rem] xl:ml-[12rem] xl:gap-10 grid grid-cols-5 justify-center items-center gap-5'>
                    <Link to={'/'} className='header-button'>
                        <span className='header-button-text'>Accueil</span>
                    </Link>
                    <Link to={"/menu"} className='header-button'>
                        <span className='header-button-text'>Menu</span>
                    </Link>
                    <Link to={'/devenir-franchise'} className='header-button'>
                        <span className='header-button-text'>Franchise</span>
                    </Link>
                    <Link to={'/nos-boutiques'} className='header-button'>
                        <span className='header-button-text'>Boutiques</span>
                    </Link>
                    <Link to={'/contactez-nous'} className='header-button'>
                        <span className='header-button-text'>Contact</span>
                    </Link>
                </div>
            </div>
            <div className='flex flex-row gap-10 pr-[1rem] pl-5'>
                    <Link to={'https://m.facebook.com/people/JungleDonuts/61554717687868/'} target='_blank'>
                        <FaFacebook className='social-media-button'/>
                    </Link>
                    <Link to={'https://www.instagram.com/jungledonuts_be'} target='_blank'>
                        <FaInstagram className='social-media-button'/>
                    </Link>
                    {/* <Link>
                        <FaLinkedin className='social-media-button'/>
                    </Link> */}
                    <Link to={'https://www.tiktok.com/@jungledonuts'} target='_blank'>
                        <FaTiktok className='social-media-button'/>
                    </Link>
                </div>
            
        </div>

    </header>
  )
}
