import React from 'react'

import { Link } from 'react-router-dom'

import HeroIcon from '../resources/misc/bubble-tea-monkey_4.png'
import Donut1 from '../resources/donuts/donuts_1_nobg.png'
import Donut2 from '../resources/donuts/donuts_2_nobg.png'
import Donut3 from '../resources/donuts/donuts_3_nobg.png'
import Donut4 from '../resources/donuts/donuts_4_nobg.png'

import Giraffe from '../resources/misc/giraffe.png'

import FounderDorian from '../resources/founders/dorian-squared.png'
import FounderTristan from '../resources/founders/tristan-squared.png'

import Liana_1 from '../resources/misc/liana_3.png'
import Liana_2 from '../resources/misc/liana_4.png'

import AvisDB from '../db/avis.json'

export default function HomePage() {
  return (
    <div>

      <Hero/>

      <div className='mx-10'>
        <MostWantedProducts/>

        <AboutUs/>

        <BecomeFranchise/>

        
      </div>

      <Avis/>

    </div>
  )
}


const Hero = () => {
  return(
    <div className='text-white hero-shadow-bg relative mt-5'>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[75px] w-screen absolute -top-9 '></img>
      <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -top-12 '></img>
      
      <div className='px-5 pt-5 sm:my-auto grid md:flex md:px-5 xl:mx-52'>
        <div className='grid '>
          <h1 className='font-title text-6xl pb-5 pt-3 md:text-6xl lg:text-6xl xl:text-8xl'>Le Paradis des <span className='bg-[var(--color-pink-2)] px-2 rounded-lg'>Donuts</span>, Bubble Tea et Milkshakes</h1>
          <h3 className='border-y-[0.15rem] p-2 font-paragraph tracking-widest text-2xl text-[var(--color-dark-1)] md:text-2xl lg:text-2xl xl:text-4xl lg:p-4 my-auto py-5 '>Laissez-vous tenter par nos donuts uniques, nos Bubble Tea rafraîchissant et nos autres gourmandises!</h3>
        
          <div className='hidden md:flex justify-start items-center px-8 pt-8 pb-4 gap-5 '>

            <Link to='/menu' className='button-2'>Menu</Link>

            <a href='https://www.ubereats.com/be/store/jungle-donuts/2Rh0vM4VVA-q-PK4OQhYqw' className='button-1' alt=''>Commandez</a>

          </div>

        </div>

        <div className='grid justify-center items-center mx-auto py-5 px-5 '>

          <img alt='hero_icon' src={HeroIcon} className='w-[350px] md:w-[750px] lg:w-[1050px] xl:w-[1440px]' />

        </div>

      </div>

      

      <div className='flex md:hidden justify-start items-center px-8 pt-8 pb-4 gap-5'>

        <Link to='/menu' className='button-1'>Menu</Link>

        <a href='https://www.ubereats.com/be/store/jungle-donuts/2Rh0vM4VVA-q-PK4OQhYqw' className='button-2' alt=''>Commandez</a>

      </div>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[75px] w-screen absolute -bottom-9 '></img>
      <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -bottom-12 '></img>

    </div>
  )
}

const MostWantedProducts = () => {

  const DonutElement = ({Image,Name}) =>{
    return(
      <div className='relative mx-auto'>

        <img alt='/' src={Image} className='absolute -top-[10rem] left-[4rem] 2xl:left-[6rem] w-[200px]'/>
        <div className='grid justify-center bg-[var(--color-white-1)] pt-16 pb-5 rounded-xl  '>
          <button className='button-3 '>{Name}</button>
        </div>


        
      </div>
    )
  }

  return(
    <div className='grid pt-10'>

      {/* <h2 className='font-title text-4xl font-bold text-white xl:text-6xl wooden-bg-1 mr-auto px-5 py-1 rounded-3xl'>Le choix des clients</h2>
       */}
      <div className='grid justify-start wooden-bg-1 w-[300px] h-full pt-5 pl-10 mb-5 xl:w-[450px] xl:pb-10'>
        <h2 className='font-title text-4xl font-bold text-white xl:text-6xl my-auto px-3 xl:mx-10 xl:pt-5'>Le choix des clients</h2>
      </div>

      <div className=' my-56 grid gap-32 lg:grid-cols-2 xl:flex xl:gap-5 '>
        <DonutElement Image={Donut1} Name={'Kinder Bueno'} />
        <DonutElement Image={Donut3} Name={'Bubble Gum'} />
        <DonutElement Image={Donut2} Name={'Donut Oreo'} />
        <DonutElement Image={Donut4} Name={'Donut Nutella'} />

      </div>

    </div>
  )
}

const AboutUs = () => {
  return(
    <div className='text-white grid pb-5 pt-5 '>
      {/* <h2 className='font-title text-4xl font-bold text-white xl:text-6xl bg-[var(--color-pink-2)] mr-auto px-5 py-1 mb-5 rounded-3xl'>Qui sommes-nous ?</h2> */}

      {/* <div className='grid justify-start wooden-bg-1 w-[250px] pt-5 pl-10 mb-5'>
        <h2 className='font-title text-4xl font-bold text-white xl:text-6xl mr-auto px-0 py-1'>Qui sommes-nous?</h2>
      </div> */}

      <div className='grid justify-start wooden-bg-1 w-[300px] h-full pt-5 pl-10 mb-5 xl:w-[450px]'>
        <h2 className='font-title text-4xl font-bold text-white xl:text-6xl my-auto px-3 xl:mx-10 xl:pt-5'>Qui sommes-nous?</h2>
      </div>

      <div className=' grid mt-5 text-center'>

        

        <div className='grid relative md:flex md:gap-5 justify-center items-center py-5 px-5 bg-[var(--color-green-3)] '>

          <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -top-12 '></img>
          <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -top-12 '></img>
          
          <div className='grid md:flex 2xl:mx-52'>
            <img src={FounderDorian} alt='dorian_photo' className='w-[200px] h-[200px] mx-auto rounded-full border-5 border-[var(--color-orange-2)]'></img>

            <div className='grid gap-5 md:mr-5'>
              <div className='grid'>
                <h4 className='font-bold text-2xl py-3 font-paragraph tracking-wider'>Rencontrez Dorian, le PDG et fondateur de JungleDonuts.</h4>
                <p className='text-lg'>
                  À seulement 21 ans, Dorian a décidé de transformer sa passion en une aventure entrepreneuriale excitante. 
                  En septembre 2023, il a lancé JungleDonuts avec une vision audacieuse : créer un paradis gourmand où les amoureux de sucreries peuvent trouver une variété de délices uniques et délicieux.
                </p>
              </div>

              <div className='grid'>
                <h4 className='font-bold text-2xl py-3  font-paragraph tracking-wider'>L'aventure commence</h4>
                <p className='text-lg'>
                  Avec son amour pour l'innovation culinaire, Dorian a rapidement réalisé qu'il avait besoin d'un partenaire tout aussi passionné pour faire de JungleDonuts une réalité. 
                  C'est là que Tristan est intervenu.

                </p>
              </div>
            </div>
          </div>

          {/* <img src={FounderDorian} alt='dorian_photo' className='w-[300px] mx-auto hidden md:block rounded-xl'></img> */}
        </div>

        <div className='grid relative md:flex md:gap-5 justify-center items-center py-5 px-5 bg-[var(--color-orange-2)] '>

          <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -top-12 '></img>
          <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -top-12 '></img>

          <div className='grid md:flex 2xl:mx-52'>
            <img alt='tristan_photo' src={FounderTristan} className='block md:hidden w-[200px] h-[200px] mx-auto rounded-full border-5 border-[var(--color-green-3)]'></img>

            <div className='grid gap-5 md:ml-5'>

              <div className='grid'>
                <h4 className='font-bold text-2xl py-3  font-paragraph tracking-wider'>La rencontre avec Tristan </h4>
                <p className='text-lg'>
                  Ensemble, ils ont fusionné leurs idées créatives pour donner naissance à JungleDonuts, un lieu où chaque bouchée est une expérience en soi.

                </p>
              </div>

              <div className='grid'>
                <h4 className='font-bold text-2xl py-3  font-paragraph tracking-wider'>Innovation et Passion</h4>
                <p className='text-lg'>
                  JungleDonuts ne se contente pas d'offrir des donuts classiques. Notre équipe s'efforce constamment de repousser les limites de la créativité culinaire, 
                  en proposant une gamme variée de gourmandises, des Bubble Teas rafraîchissants aux Milkshakes indulgents, en passant par les Cookies moelleux. 
                  Chaque produit est méticuleusement conçu pour éveiller vos papilles et vous transporter dans un monde de saveurs exaltantes.
                </p>
              </div>

              <div className='grid'>
                <h4 className='font-bold text-2xl py-3  font-paragraph tracking-wider'>L'engagement envers la qualité</h4>
                <p className='text-lg'>          
                  Chez JungleDonuts, nous mettons un point d'honneur à n'utiliser que les meilleurs ingrédients. 
                  Chaque Donut, chaque Bubble Tea, chaque Milkshake est préparé avec amour et attention, pour vous offrir une expérience gustative inoubliable à chaque visite.
                </p>
              </div>

            </div>

            <img alt='tristan_photo' src={FounderTristan} className='hidden md:block w-[200px] h-[200px] mx-auto rounded-full border-5 border-[var(--color-green-3)]'></img>

          </div>
        </div>
      </div>
      
    </div>
  )
}

// const BecomeFranchise = () => {
//   return(
//     <div className='grid lg:grid-flow-col pt-20 pb-10'>
//       <h4 className='font-title text-6xl font-bold text-white bg-[var(--color-green-1)] py-3 px-3
//                       xl:text-8xl'>Devenir Franchise</h4>
      
//       <div className='grid'>
//         <div className='bg-[var(--color-orange-1)] lg:mt-10 xl:mt-20 px-3 py-2'>
//           <h4 className='font-title text-4xl text-[var(--color-dark-2)] xl:text-6xl'>Vous voulez rejoindre la jungle ? N'hésitez plus et remplissez notre formulaire !</h4>
//           <div className='grid md:flex'>
//             <div className='py-5 '>
//               <Link to={"/devenir-franchise"} className='button-1 text-lg text-white'>Franchissez-Vous !</Link>
//             </div>
//             <div className='md:w-[300px] xl:w-[400px] mx-auto'>
//               <img alt='' src={Giraffe}></img>
//             </div>
//           </div>
//         </div>
        
//       </div>
      
//     </div>
//   )
// }

const BecomeFranchise = () => {

  return(
    <div className='grid relative pt-20 pb-10 text-center bg-[var(--color-green-1)] green-paws-bg-1'>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -top-12 '></img>
      <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -top-12 '></img>

      <h4 className='font-title title-shadow text-5xl font-bold text-white py-3 px-3
                      lg:text-8xl' >Une Franchise ?</h4>

      <h4 className='font-title text-white text-3xl text-[var(--color-dark-2)] lg:text-4xl lg:mx-56'>Rejoignez notre jungle de gourmandise !</h4>
      
      <div className='py-5 '>
        <Link to={"/devenir-franchise"} className='button-1 text-lg text-white'>Franchissez-Vous !</Link>
      </div>

      <div className='md:w-[300px] lg:w-[400px] mx-auto'>
        <img alt='' src={Giraffe}></img>
      </div>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -bottom-12 '></img>
      <img src={Liana_2} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -bottom-12 '></img>
      
    </div>
  )
}


const Avis = () => {
  return(
    <div className=' px-2 py-14 text-white grid'>
      
      {/* <div className='grid justify-start wooden-bg-1 w-[250px] pt-5 pl-10'>
        <h2 className='font-title text-4xl font-bold text-white xl:text-6xl mr-auto px-5 py-1'>Avis clients</h2>
      </div> */}

      <div className='grid justify-start wooden-bg-1 w-[300px] h-full pt-5 pl-10 mb-5 xl:w-[450px] xl:pb-10'>
        <h2 className='font-title text-4xl font-bold text-white xl:text-6xl mb-auto my-auto px-3 xl:ml-10'>Avis clients <span className='invisible xl:hidden'>......</span></h2>
      </div>

      <div className='avis-bg-1 py-32 mt-5'>
        <div className='grid lg:grid-cols-2 gap-3 p-2 mt-5 lg:mx-20 xl:mx-52 2xl:mx-72'>
          {
            AvisDB.data.map((value,key) => {
              return(
                <div key={key} className=' text-center rounded-xl bg-[var(--color-orange-2-25)]  mb-auto shadow-md shadow-[var(--color-dark-2)]'>
                  <h5 className='text-xl font-bold py-2' style={{textShadow: "#000 1px 2px 5px" }}> ~ {value.name} ~ </h5>
                  <p className='border-t-[0.25rem] mx-3 py-3'><span className='text-3xl'>"</span>{value.description}<span className='text-3xl'>"</span></p>
                </div>
              )
            })
          }
        </div>
      </div>
      
    </div>
  )
}