import React, { useRef, useState } from 'react'

import emailjs from '@emailjs/browser';

import MyImage from '../resources/misc/panther.png'

import Liana_1 from '../resources/misc/liana_3.png'
import Liana_2 from '../resources/misc/liana_4.png'

export default function FranchisePage() {
  return (
    <div className=''>

      

      <div className='mx-auto py-12'>
        
        <FranchiseForm/>

      </div>

      
    </div>
  )
}


const FranchiseForm = () => {

  const formRef = useRef();
  const [sendMessage,setSendMessage] = useState('')
  const [disableButton,setDisableButton] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    
    setDisableButton(true)
    
    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_FRANCHISE_TEMPLATE_ID, formRef.current, process.env.REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                setSendMessage("Votre email a bien été envoyé !")
                setDisableButton(false)
                // Add any success message or logic here
            }, (error) => {
                console.error('Email sending failed:', error.text);
                 setSendMessage("Votre email n'a pas été envoyé !")
                // Add any error handling logic here
            });
  }

  return(
    <form ref={formRef} className='relative grid justify-center gap-3 old-paper-bg-1 py-5 px-3 shadow-lg mx-auto rounded-xl max-w-[600px] ' onSubmit={handleSubmit}>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -top-12 '></img>
      <img src={Liana_1} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -top-12 '></img>

      <h2 className='text-center text-2xl md:text-3xl uppercase font-paragraph tracking-wider'>Rejoignez-nous en tant que franchisé</h2>
      <p className='text-center text-[var(--color-orange-3)] text-lg md:text-xl font-title tracking-wider' >Envie de créer un paradis sucré avec des donuts sur mesure, unique et surtout frais ? Avec des boissons exotiques et rafraîchissante ? Remplissez le formulaire ci-dessous pour nous rejoindre ! Nous avons tellement hâte de vous accueillir. </p>
      <div className='grid'>
        <h3 className='text-center text-[var(--color-orange-3)] text-lg md:text-xl font-title tracking-wider px-10'>Rejoignez-nous pour bénéficier de nombreux autres avantages :</h3>
        <ul className='text-[var(--color-orange-3)] text-lg md:text-xl font-title tracking-wider list-disc md:px-20' >
          {/* <li className='list-item'>Reconnaissance de la marque</li>
          <li className='list-item'>Soutien et formation</li>
          <li className='list-item'>Modèle Marketing et publicité</li>
          <li className='list-item'>Réputation et confience des consommateurs</li>
          <li className='list-item'>Innovation et développement de produits</li>
          <li className='list-item'>Accès à des emplacements privilégiés</li> */}
          <li className='list-item'>Notoriété de la marque</li>
          <li className='list-item'>Support et formation</li>
          <li className='list-item'>Stratégies de marketing et de publicité</li>
          <li className='list-item'>Réputation et confiance des consommateurs</li>
          <li className='list-item'>Innovation et développement de produits</li>
          <li className='list-item'>Accès à des emplacements de choix</li>
        </ul>
      </div>
      {/* <img src={MyImage} alt='' className='w-[150px] mx-auto lg:w-[250px]'></img> */}

      <div className='grid'>
        <div className='grid md:grid-cols-2 gap-3'>
          <div className='grid'>
            <label className='franchise-label'>Votre Nom <span className='text-red-500 font-bold'>*</span></label>
            <input required name='user_last_name' type='text' placeholder='Nom' className='input-franchise'></input>
          </div>

          <div className='grid'>
            <label className='franchise-label'>Votre Prénom <span className='text-red-500 font-bold'>*</span></label>
            <input required name='user_first_name' type='text' placeholder='Prénom' className='input-franchise'></input>
          </div>
        </div>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Apport personnel <span className='text-red-500 font-bold'>*</span></label>
        <select name='user_apport_personnel' className='input-franchise'>
          <option value={'30 000€'}>30 000€</option>
          <option value={'40 000€'}>40 000€</option>
          <option value={'50 000€'}>50 000€</option>
          <option value={'Autre'}>Autre</option>
        </select>
      </div>
      
      <div className='grid'>
        <label className='franchise-label'>Pays <span className='text-red-500 font-bold'>*</span></label>
        <select name='user_pays' className='input-franchise'>
          <option value={'Belgique'}>Belgique</option>
          <option value={'France'}>France</option>
          <option value={'Luxembourg'}>Luxembourg</option>
          <option value={'Autre'}>Autre</option>
        </select>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Ville <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_city' type='text' placeholder='Ville' className='input-franchise'></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Téléphone <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_phone' type="tel" pattern="[0-9]*" placeholder='Téléphone'className='input-franchise' ></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Email <span className='text-red-500 font-bold'>*</span></label>
        <input required name='user_email' type='email' placeholder='Email' className='input-franchise'></input>
      </div>

      <div className='grid'>
        <label className='franchise-label'>Message</label>
        <textarea name='user_message' placeholder='Votre message' className='input-franchise min-h-[150px]'></textarea>
      </div>
      
      
      <div className='pt-3 grid justify-center'>
        <p className='text-center font-bold min-h-10'>{sendMessage}</p>
        <button type='submit' className='button-3 disabled:bg-gray-400' disabled={disableButton}>Envoyer</button>
      </div>

      <img src={Liana_1} alt='ll' className='lg:hidden h-[100px] w-screen absolute -bottom-12 '></img>
      <img src={Liana_1} alt='ll' className='hidden lg:block h-[100px] w-screen absolute -bottom-12 '></img>
    
    </form>
  )
}
