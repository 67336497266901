import React from 'react'
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

import IconImage from '../resources/Logo_Jungle_Donuts_nobg.png'

export default function OurShopsPage() {

  

  return (
    <div className='grid lg:grid-cols-2 px-5 py-5 justify-center xl:gap-20'>
      
      <div className='vertial-post-bg my-5 lg:my-0'>

        <h2 className='text-center font-title text-4xl font-bold text-white xl:text-6xl mb-auto px-3 xl:mx-10 xl:pt-5 underline'>Boutiques</h2>

        <div className='grid py-5 mb-5 lg:mx-10 list-style-type:disc; px-10 md:px-20 lg:px-5 xl:px-10'>
          <h2 className='list-item font-title text-white text-lg md:text-2xl lg:text-3xl'> Rue du Pont Neuf 62, 6000 Charleroi</h2>
          <h2 className='list-item font-title text-white text-lg md:text-2xl lg:text-3xl'> Shopping Cora Châtelineau (Stand éphémère)</h2>
        </div>

      </div>
      
      <MyMap/>

    </div>
  )
}


const MyMap = () => {

  const locations = [
    {
      key: 'Jungle Donuts Main',
      location: { lat: 50.40794416176241, lng:  4.44724517924 }
    },
    {
      key: 'Jungle Donuts Cora éphémère',
      location: { lat: 50.410626, lng:  4.492724 }
    }

  ];

  return(
    <div className='grid justify-center'>
        <APIProvider apiKey={process.env.REACT_APP_GMAP}>
          <Map
            className='w-screen h-[650px] lg:w-[500px] xl:w-[600px] 2xl:w-[800px]'
            defaultCenter={{lat: 50.408119234281294, lng: 4.447438150923083}}
            defaultZoom={12.5}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
          > 
            {
              locations.map((value,key) => {
                return(
                  <Marker
                    position={value.location}
                    title= {value.key}
                    icon={{
                      url: IconImage,
                      scaledSize: { width: 100, height: 75 }        // scaledSize: { width: 150, height: 125 }
                    }}
                    />
                )
              })
            }
          </Map>

        </APIProvider>
      </div>
  )
}

// const MyMap = () => {
//   const libraries = ['places'];
//   const mapContainerStyle = {
//     width: '100vw',
//     height: '100vh',
//   };
//   const center = {
//     lat: -33.8567844, // default latitude
//     lng: 151.213108, // default longitude
//   };

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: process.env.REACT_APP_GMAP,
//     libraries,
//   });

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }



//   return (
//     <div className=''>
//       <GoogleMap
//         mapContainerStyle={mapContainerStyle}
//         zoom={15}
//         center={center}
//       >
//         <Marker
//           key={"operaHouse"}
//           position={{ lat: -33.8567844, lng: 151.213108  }}>
//         </Marker>

//         {/* {locations.map( (value,key) => (
//           <Marker
//             key={value.key}
//             position={value.location}>
//           </Marker>
//         ))} */}

//       </GoogleMap>
//     </div>
//   );
// };