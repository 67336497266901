import { BrowserRouter,Routes,Route } from "react-router-dom";
import HomePage from "./pages/HomePage";

import Header from "./components/Header";
import Footer from "./components/Footer";
import MenuPage from "./pages/MenuPage";
import FranchisePage from "./pages/FranchisePage";
import ContactUsPage from "./pages/ContactUsPage";
import OurShopsPage from "./pages/OurShopsPage";


function App() {
  return (
    <div className="general-bg min-h-screen">

      <BrowserRouter>

        <Header/>

        <Routes>

          
          <Route exact path="/" element={<HomePage/>} />
          <Route exact path="/menu" element={<MenuPage/>} />
          <Route exact path="/devenir-franchise" element={<FranchisePage/>} />
          <Route exact path="/contactez-nous" element={<ContactUsPage/>} />
          <Route exact path="/nos-boutiques" element={<OurShopsPage/>} />

        </Routes>
      

        <Footer/>

      </BrowserRouter>

    </div>
  );
}

export default App;
