import React from 'react'

import LOGO from '../resources/Logo_Jungle_Donuts_nobg.png'

import UberLogo from '../resources/social/Eats App Icon_Android_Circle.png'
import TakeAwayLogo from '../resources/social/397953-JET-Logo-Orange-Secondary-Vertical-Stacked-RGB-f50126-original-1627476396.png'

import { FaFacebook,FaInstagram,FaLinkedin,FaTiktok } from "react-icons/fa";


export default function Footer() {
  return (
    <footer className='pt-10 pb-5  bg-black'>

      <div className='flex flex-col md:flex-row md:justify-between xl:justify-center'>
        <img alt='' src={LOGO} className='w-[150px] md:w-[200px] my-auto'></img>
        <div className='grid grid-cols-2 text-white xl:grid-cols-4'>
          <div className='grid justify-center px-5'>
            <h2 className='text-xl font-bold pb-3'>Contact</h2>
            <span>contact@jungledonuts.be</span>
            <span className='pt-2'>Rue du Pont Neuf 62, 6000 Charleroi</span>
            <div className='grid grid-flow-col py-5'>
              <SocialMediaIcon Icon={<FaFacebook/>} Href={'https://m.facebook.com/people/JungleDonuts/61554717687868/'} />
              <SocialMediaIcon Icon={<FaInstagram/>} Href={'https://www.instagram.com/jungledonuts_be'} />
              {/* <SocialMediaIcon Icon={<FaLinkedin/>} Href={'https://www.linkedin.com/'} /> */}
              <SocialMediaIcon Icon={<FaTiktok/>} Href={'https://www.tiktok.com/@jungledonuts'} />
            </div>
          </div>
          <div className='flex flex-col px-5'>
            <h2 className='text-xl font-bold pb-3'>
              Livraison
            </h2>
            <div className='grid grid-flow-col gap-2 justify-center items-center'>
              <a href='https://www.ubereats.com/be/store/jungle-donuts/2Rh0vM4VVA-q-PK4OQhYqw' target='_blank' rel="noreferrer noopener">
                <img src={UberLogo} alt='/' className='w-[100px] rounded-full'/>
              </a>
              <a href='https://www.takeaway.com/be-fr/menu/jungle-donuts?&adj_tracker=2i96ia4&adj_campaign=11483881860&k1111=k1111&campaignname=CM_S_G_BEL_FR_%5BRES%5D_%5BENGM%5D_SC_Charleroi_1001334&utm_source=google&utm_medium=cpc&utm_campaign=CM_S_G_BEL_FR_%5BRES%5D_%5BENGM%5D_SC_Charleroi_1001334&gad_source=1&gclid=Cj0KCQjwpZWzBhC0ARIsACvjWRMFhUogIJwpD2qBjKLpuPUKAlMpcEqFJFIgLgrMQ6Iw8BdIOh50J7IaAl2YEALw_wcB&gclsrc=aw.ds' target='_blank' rel="noreferrer noopener">
                <img src={TakeAwayLogo} alt='/' className='w-[100px]'/>
              </a>
            </div>
          </div>

          <div className='grid justify-center px-5'>
            <h2 className='text-xl font-bold pb-3'>
              Horaire
            </h2>
            <div className='grid grid-flow-col gap-2 justify-center items-center'>
              <div className='grid'>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Lundi : </label>
                      <label className='text-green-200'> 10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Mardi : </label>
                      <label className='text-green-200'>10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Mercredi : </label>
                      <label className='text-green-200'>10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Jeudi : </label>
                      <label className='text-green-200'>10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Vendredi : </label>
                      <label className='text-green-200'>10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Samedi : </label>
                      <label className='text-green-200'>10:00 - 19:00</label>
                    </div>
                    <div className='grid grid-cols-2 gap-5'>
                      <label className='ml-auto'>Dimanche : </label>
                      <label className='text-red-500'> Fermé</label>
                    </div>
                  </div>
            </div>
          </div>

          <div className='flex flex-col px-5'>
            <h2 className='text-xl font-bold pb-3'>
              Liens Rapides
            </h2>
            <ul className='lg:px-5 grid lg:grid-cols-2 gap-2'>
                <li><a href='/' className='px-2 py-1 text-blue-500 underline'>- Accueil</a></li>
                <li><a href='/menu' className='px-2 py-1 text-blue-500 underline'>- Menu</a></li>
                <li><a href='/devenir-franchise' className='px-2 py-1 text-blue-500 underline'>- Devenir Franchise</a></li>
                <li><a href='/contactez-nous' className='px-2 py-1 text-blue-500 underline'>- Contact</a></li>
                <li><a href='/nos-boutiques' className='px-2 py-1 text-blue-500 underline'>- Nos Boutiques</a></li>
              </ul>
          </div>
        </div>
      </div>

      <div className='text-white text-lg flex gap-1 justify-center pt-5'>

        Powered by <a className='text-blue-500 underline' href='https://www.prowebsolutions.online/#/fr/home' target='_blank' rel="noreferrer noopener">Pro Web Solutions</a>

      </div>

    </footer>
  )
}


const SocialMediaIcon = ({Icon,Href}) => {
  return(
    <div>
      <a href={Href} target='_blank' rel="noreferrer noopener" className='text-3xl rounded-full' >
        {Icon}
      </a>
    </div>
  )
}